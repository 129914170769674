import { useCallback, useEffect, useMemo, useState } from 'react';

import { getChatStates } from './ChatStateHandler';
import { useTeam } from '@/app/team/context/TeamContext';
import { IMinimalDocumentSearchMatch, IMinimalDocument } from '../websocket/types';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { IChatFilterValues } from '../types';

export interface IChatMessage {
  userId: string;
  prevMessageId?: string | null;
  messageId: string;
  status: string;
  content: string;
  isFinished: boolean;
  references: {
    refId: number | null;
    documentId: string;
    matches: IMinimalDocumentSearchMatch[];
  }[];
  messagePartsHash: string;
  documents: Record<string, IMinimalDocument>;
}

export interface IChatState {
  chatId: string;
  filters: IChatFilterValues;
  messages: IChatMessage[];
  isFetching: boolean;
}

export function useChat(opts: { chatId: string; isNewChat: boolean; filters: IChatFilterValues }) {
  const { team } = useTeam();

  const { workspace, treeKey } = useWorkspace();
  const chatStores = getChatStates();
  const chatState = chatStores.getChat(opts.chatId, team.id, workspace.id, opts.isNewChat, opts.filters);

  const [state, setState] = useState<IChatState>({
    chatId: chatState.chatId,
    filters: chatState.filters,
    messages: chatState.messages,
    isFetching: chatState.isFetching,
  });

  useEffect(() => {
    const messagesChangedDisposable = chatState.messagesChangedEmitter.event((newMessages) => {
      setState((prev) => {
        return {
          ...prev,
          messages: newMessages,
        };
      });
    });

    const filtersChangedDisposable = chatState.filtersChangedEmitter.event((newFilters) => {
      setState((prev) => {
        return {
          ...prev,
          filters: newFilters,
        };
      });
    });

    const fetchingChangedEmitter = chatState.fetchingChangeEmitter.event((newIsFetching) => {
      setState((prev) => {
        return {
          ...prev,
          isFetching: newIsFetching,
        };
      });
    });

    setState({
      chatId: chatState.chatId,
      filters: chatState.filters,
      messages: chatState.messages,
      isFetching: chatState.isFetching,
    });

    return () => {
      messagesChangedDisposable.dispose();
      filtersChangedDisposable.dispose();
      fetchingChangedEmitter.dispose();
    };
  }, [opts.chatId, team.id, opts.isNewChat]);

  const sendMessage = useCallback((value: string) => {
    chatState.sendMessage(value);
  }, []);

  const setFilters = useCallback((filters: IChatFilterValues) => {
    chatState.setFilters(filters);
  }, []);

  const filterNodeIds: string[] = useMemo(() => {
    const nodeIds = new Set<string>();
    for (const folderId of state.filters.selectedFolderIds) {
      nodeIds.add(folderId);
    }
    for (const documentId of state.filters.selectedDocumentIds) {
      nodeIds.add(documentId);
    }
    return [...nodeIds];
  }, [state.filters.selectedFolderIds, state.filters.selectedDocumentIds, treeKey]);

  return {
    state,
    sendMessage,
    setFilters,
    filterNodeIds,
  };
}
