import { classNames } from '@/utils/classnames';
import { Check as CheckIcon } from '@phosphor-icons/react';
import { useId } from 'react';

export interface ICheckButton {
  labelText: string;
  isChecked?: boolean;
  onCheck?: (newValue: boolean) => void;
}

export const CheckButton: React.FC<ICheckButton> = (props) => {
  const { labelText, isChecked, onCheck } = props;
  const id = useId();

  return (
    <div
      className={classNames('flex w-full justify-between rounded px-1 cursor-pointer', {
        'bg-blue-50 hover:bg-blue-100': isChecked,
        'bg-gray-200 hover:bg-gray-300': !isChecked,
      })}
      tabIndex={0}
      onClick={() => {
        onCheck?.(!isChecked);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault(); // Prevent page scroll on space
          onCheck?.(!isChecked);
        }
      }}
      role="checkbox"
      aria-checked={isChecked}
    >
      <div>{labelText}</div>
      {!!isChecked && (
        <div className="mt-1 mr-1">
          <CheckIcon className="w-4 h-4" />
        </div>
      )}
    </div>
  );
};
