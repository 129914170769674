import React, { useMemo } from 'react';
import { File as FileIcon, Building as BuildingIcon, Globe as GlobeIcon } from '@phosphor-icons/react';

import { ISelectionCardOption, SelectionCards } from '@/components/SelectionCards';
import { QuestionsPresetType } from '../enums';

export interface IPresetTypeSelectorProps {
  value: QuestionsPresetType;
  onSelect: (value: QuestionsPresetType) => void;
}

export const PresetTypeSelector: React.FC<IPresetTypeSelectorProps> = (props) => {
  const { value, onSelect } = props;

  const cards: ISelectionCardOption<QuestionsPresetType>[] = useMemo(() => {
    return [
      {
        icon: <FileIcon className="selection-card-icon" />,
        title: 'Document (recommended)',
        description: `Analyse on a document per document basis, this ensures the highest level of precision and control. Useful for extracting key information from a document.`,
        value: QuestionsPresetType.Document,
      },
      {
        icon: <BuildingIcon className="selection-card-icon" />,
        title: 'Company (experimental)',
        description:
          'Get aggregated answers for a specific company, useful for getting a quick overview of the state of the company. (limited to 25 documents at a time)',
        value: QuestionsPresetType.Company,
      },
      {
        icon: <GlobeIcon className="selection-card-icon" />,
        title: 'Global (experimental)',
        description: 'Get aggregated answers, useful for getting a quick overview. (limited to 50 documents at a time)',
        value: QuestionsPresetType.Global,
      },
    ];
  }, []);

  return <SelectionCards cards={cards} onSelect={onSelect} value={value} />;
};
