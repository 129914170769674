import { lexer as mdLexer, Token as MdToken, Links as MdLinks } from 'marked';

export function getTokenTextValue(token: MdToken): string {
  switch (token.type) {
    case 'text':
      return token.tokens ? token.tokens.map((t) => getTokenTextValue(t as unknown as MdToken)).join('') : token.text;
    case 'strong':
    case 'em':
    case 'del':
      return token.tokens ? token.tokens.map((t) => getTokenTextValue(t as unknown as MdToken)).join('') : token.text;
    case 'escape':
      return token.text;
    case 'space':
      return '';
    case 'hr':
    case 'br':
      return '';
    case 'blockquote':
    case 'heading':
    case 'paragraph':
      return (
        (token.tokens ? token.tokens.map((t) => getTokenTextValue(t as unknown as MdToken)).join('') : token.text) +
        '\n\n'
      );
    case 'code':
      return token.text + '\n\n';
    case 'codespan':
      return token.text;
    case 'html':
    case 'tag':
      return token.text;
    case 'image':
      return `Image: ${token.text}`;
    case 'link':
      return `Link: ${token.text}`;
    case 'list':
      return (
        '\n' +
        token.items
          .map((item: any, idx: number) => {
            const order = token.start ? token.start + idx : idx + 1;
            const prefix = token.ordered ? `${order}. ` : '- ';

            return prefix + getTokenTextValue(item as unknown as MdToken);
          })
          .join('')
      );
    case 'list_item':
      return (
        (token.tokens ? token.tokens.map((t) => getTokenTextValue(t as unknown as MdToken)).join('') : token.text) +
        '\n'
      );
    case 'table':
      return token.raw;
    case 'def':
      return '';
    default:
      return '';
  }
}

function processTokens(
  tokens: MdToken[],
  opts: {
    ignoredTokens: string[];
  },
): MdToken[] {
  const { ignoredTokens } = opts;
  const result: MdToken[] = [];
  for (const token of tokens) {
    if (ignoredTokens.includes(token.type)) {
      const textValue = getTokenTextValue(token);
      const lastToken = tokens[tokens.length - 1];
      if (lastToken && lastToken.type === 'text') {
        lastToken.raw += textValue;
        lastToken.text += textValue;
      } else {
        result.push({
          type: 'text',
          raw: textValue,
          text: textValue,
        });
      }
    } else {
      const subTokens = (token as any).tokens;
      if (subTokens) {
        (token as any).tokens = processTokens(subTokens, opts);
      }
      result.push(token);
    }
  }
  return result;
}

export function parseMarkdown(
  txt: string,
  options?: {
    // These tokens should be serialised to text instead of being rendered
    ignoredTokens: string[];
  },
): {
  tokens: MdToken[];
  links: MdLinks;
} {
  const { ignoredTokens: _ignoredTokens = [] } = options || {};
  const parsed = mdLexer(txt.replace(/^[\u200B\u200C\u200D\u200E\u200F\uFEFF]/, ''));

  const ignoredTokens = [...new Set([..._ignoredTokens, 'space'])];
  let tokens = processTokens(parsed, {
    ignoredTokens,
  });

  return {
    tokens: tokens.map((v) => {
      if (v.type === 'text') {
        return {
          ...v,
          text: v.text.replace(/\s+/g, ' '),
        };
      } else {
        return v;
      }
    }),
    links: parsed.links,
  };
}
