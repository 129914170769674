import { createId } from '@paralleldrive/cuid2';
import toast from 'react-hot-toast';
import { captureException } from '@sentry/react';

import { ThrottledEmitter } from '../../../utils/emitter';
import { getClient } from '../../../contexts/websocket-context';
import { DisposableStore } from '../../../utils/disposable';
import { fetchEndpointData } from '@/utils/fetch.client';
import type { ResponseType as TeamResponseType } from '../endpoints/TeamEndpoint';

export class TeamState {
  private websocket = getClient();
  private subscribeDisposable = new DisposableStore();

  private reconnectHash = Date.now().toString(16);
  private reconnectEmitter = new ThrottledEmitter<string>();
  public onReconnect = this.reconnectEmitter.event;

  public remainingCredits = 999999;

  public categoriesHash = Date.now().toString(16);
  private categoriesUpdateEmitter = new ThrottledEmitter<string>();
  public onCategoriesUpdate = this.categoriesUpdateEmitter.event;

  private remainingCreditsUpdateEmitter = new ThrottledEmitter<number>();
  public onRemainingCreditsChange = this.remainingCreditsUpdateEmitter.event;

  constructor(public teamId: string) {
    this.websocket.onConnect(() => {
      this.subscribe().catch((err) => {
        captureException(err);
        toast.error(err.message);
      });
    });

    this.subscribe().catch((err) => {
      captureException(err);
      toast.error(err.message);
    });
  }

  private emitCategoriesUpdate(hash: string) {
    this.categoriesHash = hash;
    this.categoriesUpdateEmitter.fire(this.categoriesHash);
  }

  private updateReconnectHash(): void {
    this.reconnectHash = Date.now().toString(16);
    this.reconnectEmitter.fire(this.reconnectHash);

    // Update everything...
    this.emitCategoriesUpdate(this.reconnectHash);
  }

  private updateRemainingCredits(newRemaining: number) {
    this.remainingCredits = newRemaining;
    this.remainingCreditsUpdateEmitter.fire(newRemaining);
  }

  private cleanupSubscription() {
    this.subscribeDisposable.dispose();
    this.subscribeDisposable = new DisposableStore();
  }

  private async fetchInitialState(): Promise<void> {
    const res = await fetchEndpointData<TeamResponseType>(`/api/v1/team/data/${this.teamId}`);
    this.updateRemainingCredits(res.team.remainingCredits);
  }

  private subscribe(): Promise<void> {
    this.fetchInitialState().catch(console.error);

    return new Promise<void>((resolve, reject) => {
      const msgRef = createId();
      let isResolved = false;

      this.subscribeDisposable.add(
        this.websocket.onMessage((message) => {
          if (message.ref === msgRef) {
            if (!isResolved && message.method === 'team/subscribe-ack') {
              resolve();
              this.updateReconnectHash();
              isResolved = true;
            }

            switch (message.method) {
              case 'team/category-update': {
                this.emitCategoriesUpdate(message.data.timestamp.toString(16));
                break;
              }
              case 'team/usage-update': {
                this.updateRemainingCredits(message.data.remainingCredits);
                break;
              }
            }
          }
        }),
      );
      this.subscribeDisposable.add(
        this.websocket.onErrorMessage((message) => {
          if (message.ref === msgRef) {
            if (!isResolved) {
              reject(new Error(message.error.message));
              isResolved = true;
            }
            this.cleanupSubscription();
          }
        }),
      );
      this.websocket.send({
        ref: msgRef,
        method: 'team/subscribe',
        data: {
          teamId: this.teamId,
        },
      });
    });
  }
}

export class TeamStatesStore {
  states = new Map<string, TeamState>();

  getState(teamId: string): TeamState {
    let state = this.states.get(teamId);
    if (!state) {
      state = new TeamState(teamId);
      this.states.set(teamId, state);
    }
    return state;
  }
}

let _store: TeamStatesStore | null = null;
export function getTeamStates(): TeamStatesStore {
  if (!_store) {
    _store = new TeamStatesStore();
  }
  return _store;
}
