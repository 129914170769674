import { Link, useSearchParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { Prohibit as BanIcon, Trash as TrashIcon } from '@phosphor-icons/react';
import toast from 'react-hot-toast';
import { captureException } from '@sentry/react';

import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { StartPresetRunDialog } from './StartPresetRunDialog';
import { IPaginationVariables, usePagination } from '@/hooks/usePagination';
import { Table } from '@/components/table/Table';
import { formatDateTime } from '@/utils/date';
import { ConfirmDialog } from '@/components/dialog/ConfirmDialog';
import { getDisplayError } from '@/utils/get-display-error';
import { StatusDot } from '@/components/StatusDot';
import { Spinner, SpinnerBlock } from '@/components/Spinner';
import { fetchEndpointData } from '@/utils/fetch.client';
import type { ResponseType as WorkspacePresetRunsResponseType } from '../endpoints/WorkspacePresetRunsEndpoint';
import type { BodyType as DeletePresetRunPayload } from '../endpoints/DeletePresetRunEndpoint';
import type { BodyType as CancelPresetRunPayload } from '../endpoints/CancelPresetRunEndpoint';
import { PresetRunStatus } from '../enums';
import { Breadcrumb } from '@/components/Breadcrumb';
import { PageHeader } from '@/components/PageHeader';
import { WorkspacePermissionWrapper } from '@/app/workspace/components/WorkspacePermissionWrapper';
import { WorkspacePermissionEnum } from '@/app/auth/enum';

const PRESET_RUNS_TABLE_HEADERS = [
  {
    id: 'id',
    name: 'Name',
  },
  {
    id: 'status',
    name: 'Status',
  },
  {
    id: 'createdAt',
    name: 'Started at',
  },
  {
    id: 'delete',
    name: 'Delete',
  },
];

export const WorkspacePresetRunsPage = () => {
  const { workspace, processingState } = useWorkspace();
  const [searchParams] = useSearchParams();
  const [actionId, setActionId] = useState(0);
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const query = new URLSearchParams();
      query.set('workspaceId', workspace.id);
      if (variables.cursor) {
        query.set('cursor', variables.cursor);
      }
      query.set('take', variables.take.toString());

      const result = await fetchEndpointData<WorkspacePresetRunsResponseType>(
        `/api/v1/workspace/preset-run/list?${query.toString()}`,
      );
      return result.presetRuns ?? [];
    },
    [workspace.id],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 40,
    cursor: searchParams.get('pageCursor'),
    fetcher: pageFetcher,
    refreshToken: actionId,
  });

  const documentsProcessing = processingState.processedCount < processingState.totalCount;
  const history = page.data ?? [];
  return (
    <div className="page-content">
      <PageHeader title="Preset Runs" />

      <div className="mb-2 flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: 'Preset Runs',
            },
          ]}
        />

        <div className="flex justify-between items-center mb-4">
          <div>
            {documentsProcessing && (
              <div className="flex gap-1 items-center mb-2">
                <Spinner size={4} />
                Some documents are still being processed...
              </div>
            )}
          </div>

          <WorkspacePermissionWrapper allowedPermissions={[WorkspacePermissionEnum.RunPresets]}>
            <StartPresetRunDialog
              isProcessing={documentsProcessing}
              onStart={() => {
                setActionId(Date.now());
              }}
            />
          </WorkspacePermissionWrapper>
        </div>
      </div>

      {!!history.length && (
        <Table
          idKey="id"
          headers={PRESET_RUNS_TABLE_HEADERS}
          data={history}
          mapData={(entry) => {
            return [
              <Link to={entry.id} className="w-full hover:text-link-color">
                {[entry.name, entry.companyName].filter(Boolean).join(' - ')}
              </Link>,
              <div className="flex items-center gap-1">
                <StatusDot
                  size={3}
                  color={
                    entry.status === PresetRunStatus.Running
                      ? 'blue'
                      : entry.status === PresetRunStatus.Canceled
                        ? 'red'
                        : 'green'
                  }
                  pulse={entry.status === PresetRunStatus.Running}
                />
                {entry.status}
              </div>,
              formatDateTime(entry.createdAt),
              entry.status === PresetRunStatus.Running ? (
                <ConfirmDialog
                  triggerSize={6}
                  triggerText={<BanIcon className="button-icon" />}
                  title="Cancel preset run"
                  submitText="Cancel"
                  triggerVariant="destructive"
                  submitVariant="destructive"
                  description={`Are you sure you want to cancel this preset run?`}
                  onSubmit={async () => {
                    try {
                      const payload: CancelPresetRunPayload = {
                        presetRunId: entry.id,
                      };
                      await fetchEndpointData(`/api/v1/workspace/preset-run/cancel`, {
                        method: 'DELETE',
                        body: payload,
                      });
                      setActionId(Date.now());
                      toast.success('Preset run has been canceled');
                    } catch (err) {
                      captureException(err);
                      toast.error('Could not cancel preset run: ' + getDisplayError(err));
                    }
                  }}
                />
              ) : (
                <ConfirmDialog
                  triggerSize={6}
                  triggerText={<TrashIcon className="button-icon" />}
                  title="Delete preset run"
                  submitText="Delete"
                  triggerVariant="destructive"
                  submitVariant="destructive"
                  description={`Are you sure you want to delete this preset run?`}
                  onSubmit={async () => {
                    try {
                      const payload: DeletePresetRunPayload = {
                        presetRunId: entry.id,
                      };
                      await fetchEndpointData(`/api/v1/workspace/preset-run/delete`, {
                        method: 'DELETE',
                        body: payload,
                      });
                      setActionId(Date.now());
                      toast.success('Preset run has been deleted');
                    } catch (err) {
                      captureException(err);
                      toast.error('Could not delete preset run: ' + getDisplayError(err));
                    }
                  }}
                />
              ),
            ];
          }}
        />
      )}

      {!history.length && !page.isFetching && (
        <div className="card">
          <div>No preset runs found.</div>
        </div>
      )}

      {!history.length && page.isFetching && (
        <div>
          <SpinnerBlock message="Loading preset runs..." />
        </div>
      )}
    </div>
  );
};
