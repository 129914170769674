import React from 'react';
import { Check as CheckIcon } from '@phosphor-icons/react';

import classNames from '@/utils/classnames';

export interface ISelectionCardProps {
  icon: React.ReactNode;
  title: string;
  description: React.ReactNode;
  value: any;
  onSelect: (value: any) => void;
  isSelected?: boolean;
}

export const SelectionCard: React.FC<ISelectionCardProps> = (props) => {
  const { icon, title, description, onSelect, value, isSelected } = props;

  return (
    <div
      className={classNames(
        'relative rounded bg-gray-200 border border-gray-300  flex flex-col items-center p-4 cursor-pointer',
        {
          'border-blue-500 bg-blue-100': isSelected,
        },
      )}
      aria-role="button"
      tabIndex={0}
      onClick={() => onSelect(value)}
    >
      <div className='absolute right-0 top-0 px-3 py-2'>
        <CheckIcon
          className={classNames('h-6 w-6', {
            'text-blue-800': isSelected,
            hidden: !isSelected,
          })}
        />
      </div>
      <div className="mb-2 text-gray-700">{icon}</div>
      <div className="font-medium text-lg text-gray-900">{title}</div>
      <div className="text-center text-gray-800">{description}</div>
    </div>
  );
};

export interface ISelectionCardOption<T = any> {
  icon: React.ReactNode;
  title: string;
  description: React.ReactNode;
  value: T;
}

export interface ISelectionCardsProps<T = any> {
  value: T;
  onSelect: (value: T) => void;
  cards: ISelectionCardOption<T>[];
}

export const SelectionCards: React.FC<ISelectionCardsProps> = (props) => {
  const { value, onSelect, cards } = props;

  return (
    <div className="grid grid-cols-3 gap-2">
      {cards.map((v) => {
        return (
          <SelectionCard
            icon={v.icon}
            title={v.title}
            description={v.description}
            value={v.value}
            onSelect={onSelect}
            isSelected={value === v.value}
          />
        );
      })}
    </div>
  );
};
