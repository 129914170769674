import {
  PencilSimple as PencilIcon,
  Plus as PlusIcon,
  Trash as TrashIcon,
  DownloadSimple as DownloadIcon,
  UploadSimple as UploadIcon,
} from '@phosphor-icons/react';
import toast from 'react-hot-toast';
import React, { useCallback, useMemo, useState } from 'react';
import { captureException } from '@sentry/react';
import { useNavigate, useParams } from 'react-router-dom';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { ICategoryNode, useCategories } from '../hooks/useCategories';
import { CategoryFormDialog } from './CategoryFormDialog';
import { getDisplayError } from '../../../utils/get-display-error';
import { useTeam } from '@/app/team/context/TeamContext';
import { Button } from '../../../components/button/Button';
import {
  BodyType as CreateCategoryPayload,
  ResponseType as CreateCategoryResponseType,
} from '../endpoints/CreateCategoryEndpoint';
import { BodyType as UpdateCategoryPayload } from '../endpoints/UpdateCategoryEndpoint';
import { BodyType as DeleteCategoryPayload } from '../endpoints/DeleteCategoryEndpoint';
import { BodyType as DeleteCategoryTemplatePayload } from '../endpoints/DeleteCategoryTemplateEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { nullthrows } from '@/utils/invariant';
import { ConfirmDialog } from '@/components/dialog/ConfirmDialog';
import { useAuth } from '@/contexts/auth-context';
import { CategoriesFolderDrop, ITreeNode } from '../components/CategoriesFolderDrop';

export interface ICategoryNodeProps {
  idx: string;
  parentIdx: string;
  node: ICategoryNode;
  depth: number;
  categoryTree: ICategoryNode[];
}

const CategoryNode: React.FC<ICategoryNodeProps> = (props) => {
  const { idx, parentIdx, node, depth, categoryTree } = props;

  const { team } = useTeam();
  const [showEditDialog, setShowEditDialog] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const excludedCategoryIds = useMemo(() => {
    return [node.id];
  }, [node.id]);

  const fullIdx = parentIdx ? `${parentIdx}.${idx}` : idx;
  return (
    <div>
      <div className="flex justify-between items-center border-b border-gray-200 py-1 hover:bg-gray-100">
        <div>{`${fullIdx} ${node.name}`}</div>
        <div className="flex gap-1">
          <Button
            size={6}
            shape="square"
            onTrigger={() => {
              setShowEditDialog(true);
            }}
          >
            <PencilIcon className="w-4 h-4" />
          </Button>
          <Button
            size={6}
            shape="square"
            onTrigger={() => {
              setShowDeleteDialog(true);
            }}
          >
            <TrashIcon className="w-4 h-4" />
          </Button>
          <CategoryFormDialog
            key={`${node.id}-${node.name}-${node.parentCategoryId}-edit`}
            excludedCategoryIds={excludedCategoryIds}
            categoryTree={categoryTree}
            initialValues={{
              name: node.name,
              description: node.description,
              parentCategoryId: node.parentCategoryId,
            }}
            isOpen={showEditDialog}
            setIsOpen={setShowEditDialog}
            title="Edit category"
            submitText="Save"
            onSubmit={async (values) => {
              try {
                const payload: UpdateCategoryPayload = {
                  categoryId: node.id,
                  teamId: team.id,
                  data: {
                    name: values.name,
                    description: values.description,
                    parentCategoryId: values.parentCategoryId,
                  },
                };
                await fetchEndpointData('/api/v1/category/update', {
                  method: 'POST',
                  body: payload,
                });
                toast.success('Category updated');
              } catch (err) {
                captureException(err);
                toast.error(`Failed to update category: ${getDisplayError(err)}`);
                throw err;
              }
            }}
          />
          <ConfirmDialog
            key={`${node.id}-${node.name}-${node.parentCategoryId}-delete`}
            triggerText=""
            submitText="Delete"
            isOpen={showDeleteDialog}
            setIsOpen={setShowDeleteDialog}
            title={`Delete category - ${node.name}`}
            description="Are you sure you want to delete this category?"
            onSubmit={async () => {
              try {
                const payload: DeleteCategoryPayload = {
                  teamId: team.id,
                  categoryId: node.id,
                };
                await fetchEndpointData('/api/v1/category/delete', {
                  method: 'DELETE',
                  body: payload,
                });
                toast.success('Category removed');
              } catch (err) {
                captureException(err);
                toast.error(`Failed to delete category: ${getDisplayError(err)}`);
                throw err;
              }
            }}
          />
        </div>
      </div>

      <div>
        {node.children.map((v, idx) => {
          return (
            <CategoryNode
              key={v.id}
              categoryTree={categoryTree}
              node={v}
              depth={depth + 1}
              parentIdx={fullIdx}
              idx={`${idx + 1}`}
            />
          );
        })}
      </div>
    </div>
  );
};

const createCategory = async (data: {
  teamId: string;
  templateId: string;
  values: { name: string; description: string; parentCategoryId: string | null };
}) => {
  const { teamId, templateId, values } = data;
  const payload: CreateCategoryPayload = {
    teamId,
    data: {
      templateId,
      name: values.name,
      description: values.description,
      parentCategoryId: values.parentCategoryId,
    },
  };
  const response = await fetchEndpointData<CreateCategoryResponseType>('/api/v1/category/create', {
    method: 'POST',
    body: payload,
  });
  return response.category;
};

const handleImportedData = async (
  template: {
    teamId: string;
    templateId: string;
  },
  data: any,
) => {
  if (!Array.isArray(data)) {
    throw new Error('Invalid format');
  }

  for (const category of data) {
    const createdCategory = await createCategory({
      teamId: template.teamId,
      templateId: template.templateId,
      values: {
        name: category.name,
        description: category.description,
        parentCategoryId: null,
      },
    });

    if (category.children) {
      for (const child of category.children) {
        await createCategory({
          teamId: template.teamId,
          templateId: template.templateId,
          values: {
            name: child.name,
            description: child.description,
            parentCategoryId: createdCategory.id,
          },
        });
      }
    }
  }
};

const handleFolderImport = async (
  template: {
    teamId: string;
    templateId: string;
  },
  folder: ITreeNode,
  parentCategoryId: string | null,
) => {
  const createdCategory = await createCategory({
    teamId: template.teamId,
    templateId: template.templateId,
    values: {
      name: folder.name,
      description: '',
      parentCategoryId,
    },
  });

  if (folder.children) {
    for (const child of folder.children) {
      await handleFolderImport(template, child, createdCategory.id);
    }
  }
};

export const CategoriesPage = () => {
  const { team } = useTeam();
  const { me } = useAuth();
  const navigate = useNavigate();
  const { categoryTemplateId: _templateId } = useParams<{ categoryTemplateId: string }>();
  const templateId = nullthrows(_templateId, 'templateId is required');
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [createKey, setCreateKey] = React.useState(0);
  const { template, categories, categoryTree, isLoading } = useCategories(templateId);
  const [isImporting, setIsImporting] = useState(false);

  const categoryCount = categories.length;
  const handleDropImport = useCallback(
    async (folders: ITreeNode[]) => {
      if (categoryCount > 0) {
        return;
      }

      if (folders.length > 0) {
        setIsImporting(true);
        for (const folder of folders) {
          try {
            await handleFolderImport({ teamId: team.id, templateId }, folder, null);
          } catch (err) {
            setIsImporting(false);
            toast.error(`Failed to import folder: ${getDisplayError(err)}`);
          }
        }
        setIsImporting(false);
      }
    },
    [categoryCount],
  );

  if (isLoading) {
    return null;
  }

  if (!template) {
    return (
      <div className="page-content">
        <PageHeader title="Category template not found" />

        <div>
          <div className="mb-4 flex justify-between">
            <Breadcrumb
              items={[
                {
                  name: 'Category Templates',
                  to: '..',
                },
                {
                  name: 'Not Found',
                },
              ]}
            />
          </div>

          <div>Category template not found.</div>
        </div>
      </div>
    );
  }

  const title = template.name ?? '';
  return (
    <div className="page-content">
      <PageHeader title={`Category template - ${title}`} />

      <div>
        <div className="mb-4 flex justify-between">
          <Breadcrumb
            items={[
              {
                name: 'Category Templates',
                to: '..',
              },
              {
                name: title,
              },
            ]}
          />

          <div className="flex gap-2">
            {me.isSuperUser && (
              <Button
                onTrigger={() => {
                  const categories = JSON.stringify(categoryTree);

                  const url = URL.createObjectURL(new Blob([categories], { type: 'application/json' }));
                  const link = document.createElement('a');
                  link.href = url;
                  link.download = `category-export-${title.replace(/\s+/g, '-').toLowerCase()}.json`;
                  link.click();
                }}
              >
                <DownloadIcon className="button-icon" />
              </Button>
            )}

            {me.isSuperUser && !categories.length && (
              <Button
                isLoading={isImporting}
                onTrigger={() => {
                  // Create an input element
                  const input = document.createElement('input');
                  input.type = 'file';
                  input.accept = '.json';

                  // Handle file selection
                  input.onchange = (event) => {
                    setIsImporting(true);

                    // @ts-ignore
                    const file = event.target.files[0];
                    if (!file) {
                      setIsImporting(false);
                      return;
                    }

                    const reader = new FileReader();
                    reader.onload = (e) => {
                      try {
                        // @ts-ignore
                        const jsonData = JSON.parse(e.target.result);
                        handleImportedData({ teamId: team.id, templateId }, jsonData)
                          .then(() => {
                            setIsImporting(false);
                            toast.success('Data imported');
                          })
                          .catch((err) => {
                            setIsImporting(false);
                            toast.error(`Failed to import data: ${getDisplayError(err)}`);
                          });
                      } catch (error) {
                        setIsImporting(false);
                        toast.error('Invalid file');
                      }
                    };
                    reader.readAsText(file);

                    input.remove();
                  };

                  // Trigger file picker
                  input.click();
                }}
              >
                <UploadIcon className="button-icon" />
              </Button>
            )}

            {categoryCount === 0 && <CategoriesFolderDrop onDrop={handleDropImport} />}

            <ConfirmDialog
              triggerIconLeft={<TrashIcon className="button-icon" />}
              triggerVariant="destructive"
              submitVariant="destructive"
              triggerText="Delete Template"
              title="Delete Template"
              description="Are you sure you want to delete this template?"
              submitText="Delete"
              onSubmit={async () => {
                try {
                  const payload: DeleteCategoryTemplatePayload = {
                    templateId: templateId,
                    teamId: team.id,
                  };
                  await fetchEndpointData('/api/v1/category-template/delete', {
                    method: 'DELETE',
                    body: payload,
                  });
                  toast.success('Category template removed');
                  navigate('..');
                } catch (err) {
                  captureException(err);
                  toast.error(`Failed to delete category template: ${getDisplayError(err)}`);
                  throw err;
                }
              }}
            />
          </div>
        </div>

        <div>
          <div className="flex justify-between items-center border-b border-gray-200 py-1">
            <div className="font-medium">Name</div>
            <div>
              <Button
                size={6}
                shape="square"
                onTrigger={() => {
                  setShowCreateDialog(true);
                }}
              >
                <PlusIcon className="w-4 h-4" />
              </Button>
              <CategoryFormDialog
                key={`category-create-${createKey}`}
                isOpen={showCreateDialog}
                setIsOpen={setShowCreateDialog}
                title="Create category"
                submitText="Create"
                categoryTree={categoryTree}
                onSubmit={async (values, helpers) => {
                  try {
                    const payload: CreateCategoryPayload = {
                      teamId: team.id,
                      data: {
                        templateId,
                        name: values.name,
                        description: values.description,
                        parentCategoryId: values.parentCategoryId,
                      },
                    };
                    await fetchEndpointData('/api/v1/category/create', {
                      method: 'POST',
                      body: payload,
                    });
                    setCreateKey(Date.now());
                    helpers.resetForm();
                    toast.success('Category created');
                  } catch (err) {
                    captureException(err);
                    toast.error(`Failed to create category: ${getDisplayError(err)}`);
                    throw err;
                  }
                }}
              />
            </div>
          </div>

          <div>
            {!categories.length && <div>No categories found</div>}
            <div>
              {categoryTree.map((v, idx) => {
                return (
                  <CategoryNode
                    key={v.id}
                    categoryTree={categoryTree}
                    node={v}
                    depth={0}
                    parentIdx=""
                    idx={`${idx + 1}`}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
