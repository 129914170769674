export function parseNumberInput(value: string, precision = 0): number {
  const strValue = `${value}`.replace(/,+/g, '.');
  const parts = strValue.split('.');
  let inputValue = strValue;
  if (parts.length > 1) {
    const pointValue = parts.pop();
    inputValue = parts.join('') + '.' + pointValue;
  }
  const parsed = parseFloat(inputValue);
  // Faster than log?
  const precisionMultiplier = +('1' + '0'.repeat(precision));
  const multiplied = Math.round(parsed * precisionMultiplier);
  if (isNaN(multiplied)) {
    throw new Error('String is not a number');
  }
  return multiplied;
}

export function formatNumber(value: number, precision = 0, decimalSeperator = '.'): string {
  const isNegative = value < 0;
  const asString = `${Math.abs(Math.round(value))}`;
  const firstNumbers = asString.slice(0, -precision);
  const lastNumber = asString.slice(-precision);
  return `${isNegative ? '-' : ''}${firstNumbers || '0'}${decimalSeperator}${lastNumber.padStart(2, '0')}`;
}

export function NaNToZero(value: number): number {
  if (isNaN(value)) {
    return 0;
  } else {
    return value;
  }
}

export function randomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const RANDOM_VALUES = new Map<string, number>();
export function getRandomValue(key: string) {
  const value = RANDOM_VALUES.get(key) ?? Math.random();
  RANDOM_VALUES.set(key, value);
  return value;
}

export function tryParseFloat(value: any): number | null {
  const parsed = parseFloat(value);
  if (isNaN(parsed)) {
    return null;
  } else {
    return parsed;
  }
}
