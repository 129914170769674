import {
  Gear as SettingsIcon,
  House as HomeIcon,
  File as FileIcon,
  Building as BuildingIcon,
  Scan as ScanTextIcon,
  Chat as MessageSquareIcon,
  List as LogsIcon,
  Sliders as SlidersHorizontalIcon,
  Tag as TagIcon,
} from '@phosphor-icons/react';
import { useMemo } from 'react';

import { useAuth } from '../../contexts/auth-context';
import { useTeam } from '@/app/team/context/TeamContext';
import { ISidebarButton, TeamDashboardTemplate } from './TeamDashboardTemplate';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { BaseButton } from '../button/BaseButton';
import { useNavigate } from 'react-router-dom';
import { TeamPermissionEnum, WorkspacePermissionEnum } from '@/app/auth/enum';
import { nullthrows } from '@/utils/invariant';
import { Tooltip } from '../tooltip/Tooltip';
import { Spinner } from '../Spinner';

export interface IDashboardProps {
  children: React.ReactNode;
}

export const WorkspaceDashboard: React.FC<IDashboardProps> = (props) => {
  const { children } = props;
  const { me, myTeams } = useAuth();
  const { team: selectedTeam } = useTeam();
  const { workspace, isSyncingTree } = useWorkspace();
  const navigate = useNavigate();

  const currentTeam = nullthrows(
    myTeams.find((t) => t.team.id === selectedTeam?.id),
    'Team not found',
  );

  const sidebarTopButtons = useMemo(() => {
    const buttons: ISidebarButton[] = [
      {
        icon: <HomeIcon className="button-icon" />,
        title: 'Overview',
        to: `/app/t/${selectedTeam.id}/workspace/${workspace.id}/overview`,
      },
      {
        icon: <FileIcon className="button-icon" />,
        title: 'Documents',
        to: `/app/t/${selectedTeam.id}/workspace/${workspace.id}/documents`,
      },
      {
        icon: <BuildingIcon className="button-icon" />,
        title: 'Companies',
        to: `/app/t/${selectedTeam.id}/workspace/${workspace.id}/companies`,
      },
      {
        icon: <ScanTextIcon className="button-icon" />,
        title: 'Preset runs',
        to: `/app/t/${selectedTeam.id}/workspace/${workspace.id}/preset-runs`,
      },
    ];

    if (workspace.permissions.includes(WorkspacePermissionEnum.Chat)) {
      buttons.push({
        icon: <MessageSquareIcon className="button-icon" />,
        title: 'Chat',
        to: `/app/t/${selectedTeam.id}/workspace/${workspace.id}/chat`,
      });
    }

    return buttons;
  }, [workspace, me.isSuperUser]);

  const sidebarBottomButtons = useMemo(() => {
    const buttons: ISidebarButton[] = [];

    if (currentTeam.permissions.includes(TeamPermissionEnum.ManagePresets)) {
      buttons.push({
        icon: <SlidersHorizontalIcon className="button-icon" />,
        title: 'Presets',
        to: `/app/t/${selectedTeam.id}/workspace/${workspace.id}/questions-preset`,
      });
    }

    if (workspace.permissions.includes(WorkspacePermissionEnum.ManageCategories)) {
      buttons.push({
        icon: <TagIcon className="button-icon" />,
        title: 'Categories',
        to: `/app/t/${selectedTeam.id}/workspace/${workspace.id}/categories`,
      });
    }

    if (workspace.permissions.includes(WorkspacePermissionEnum.ReadAuditLogs)) {
      buttons.push({
        icon: <LogsIcon className="button-icon" />,
        title: 'Audit Logs',
        to: `/app/t/${selectedTeam.id}/workspace/${workspace.id}/audit-logs`,
      });
    }

    if (workspace.permissions.includes(WorkspacePermissionEnum.ManageMembers)) {
      buttons.push({
        icon: <SettingsIcon className="button-icon" />,
        title: 'Settings',
        to: `/app/t/${selectedTeam.id}/workspace/${workspace.id}/settings`,
      });
    }

    return buttons;
  }, [workspace, me.isSuperUser]);

  return (
    <TeamDashboardTemplate
      breadcrumbButtons={[
        <BaseButton variant="ghost" onClick={() => navigate(`/app/t/${selectedTeam.id}`)}>
          Workspaces
        </BaseButton>,
        <div className="px-2">{workspace.name}</div>,
      ]}
      sidebarTopButtons={sidebarTopButtons}
      sidebarBottomButtons={sidebarBottomButtons}
      statusIndicatorOverwrite={
        isSyncingTree ? (
          <Tooltip text={`Syncing workspace...`}>
            <div>
              <Spinner size={4} />
            </div>
          </Tooltip>
        ) : undefined
      }
    >
      {children}
    </TeamDashboardTemplate>
  );
};
