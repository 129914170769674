import { Link } from 'react-router-dom';
import useSWR from 'swr';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { formatDate } from '../../../utils/date';
import { SpinnerBlock } from '../../../components/Spinner';
import { ResponseType as KnowlexIntegrationResponseType } from '../knowlex/endpoints/KnowlexIntegrationEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';

export const WorkspaceIntegrationsPage = () => {
  const { workspace } = useWorkspace();
  const { data: knowlexData, isLoading: knowlexFetching } = useSWR<KnowlexIntegrationResponseType>(
    `/api/v1/workspace/integration/knowlex/data/${workspace.id}`,
    fetchEndpointData,
  );

  const knowlexIntegration = knowlexData?.knowlexIntegration;

  if (!knowlexIntegration && knowlexFetching) {
    return <SpinnerBlock message="Loading..." />;
  }

  return (
    <div className="page-content">
      <PageHeader title="Integrations" />

      <div className="flex justify-between items-center mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Settings',
              to: '..',
            },
            {
              name: 'Integrations',
            },
          ]}
        />
      </div>

      <div className="grid grid-cols-4 gap-4">
        <Link to="knowlex" className="block card cursor-pointer" data-clickable="true">
          <div className="card-heading flex justify-between">Knowlex</div>
          <div>
            <div>
              {knowlexIntegration?.createdAt ? `Linked at ${formatDate(knowlexIntegration.createdAt)}` : 'Not linked'}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
