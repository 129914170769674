import React, { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import {
  File as FileIcon,
  Folder as FolderIcon,
  PencilSimple as PencilIcon,
  Plus as PlusIcon,
  MagnifyingGlass as SearchIcon,
  NotePencil as NotePencilIcon,
  Trash as TrashIcon,
  Warning as WarningIcon,
  X as XIcon,
  MapPin as MapPinIcon,
  Translate as TranslateIcon,
  CaretUpDown as CaretUpDownIcon,
  CaretDown as CaretDownIcon,
  CaretUp as CaretUpIcon,
} from '@phosphor-icons/react';
import { useNavigate, useParams } from 'react-router-dom';
import { captureException } from '@sentry/react';

import { TREE_ROOT_ID, TreeNode } from '@/app/workspaceDocument/tree/WorkspaceDocumentTree';
import { ConfirmDialog } from '@/components/dialog/ConfirmDialog';
import { useWorkspace } from '../../workspace/context/WorkspaceContext';
import { fetchEndpointData } from '@/utils/fetch.client';
import { getDisplayError } from '@/utils/get-display-error';
import type { BodyType as DeleteDocumentsPayload } from '../../workspaceDocument/endpoints/DeleteWorkspaceDocumentsEndpoint';
import type { BodyType as DeleteFoldersPayload } from '../../workspaceFolder/endpoints/DeleteWorkspaceFoldersEndpoint';
import type { BodyType as RenameDocumentPayload } from '../endpoints/RenameDocumentEndpoint';
import type { BodyType as UpdateFolderDocumentPayload } from '../../workspaceFolder/endpoints/UpdateWorkspaceFolderEndpoint';
import { Button } from '@/components/button/Button';
import { StyledCheckbox } from '@/components/Checkbox';
import { formatDate } from '@/utils/date';
import { CreateFolderDialog } from '@/app/workspaceFolder/components/CreateFolderDialog';
import { DocumentTreeBreadcrumb } from '../components/DocumentTreeBreadcrumb';
import { Spinner, SpinnerBlock } from '@/components/Spinner';
import { PageHeader } from '@/components/PageHeader';
import { Breadcrumb } from '@/components/Breadcrumb';
import { InputDialog } from '@/components/dialog/InputDialog';
import { MoveDialog } from '../components/MoveDialog';
import { BulkDocumentMetadataDialog } from '../components/BulkMetadataDialog';
import { Tooltip } from '@/components/tooltip/Tooltip';
import { Tag } from '@/components/Tag';
import { Input } from '@/components/input/Input';
import { useSearch } from '../hooks/useSearch';
import { numberAwareTextSort } from '@/utils/text';
import { DocumentProcessingCountDialog } from '../components/DocumentProcessingCountDialog';
import { InvalidDocumentsCountDialog } from '../components/InvalidDocumentsCountDialog';
import { DocumentIndexingStatus } from '../enums';
import { PendingDocumentUploadsDialog } from '../components/PendingDocumentUploads';
import { FullPageDropUpload } from '../components/FullPageDropUpload';
import { UploadButton } from '../components/UploadButton';
import { ALLOWED_MIMETYPES } from '../constants';
import classNames from '@/utils/classnames';
import { useAppContext } from '@/contexts/app-context';

const COLUMNS_TEMPLATE = '2rem 1fr auto 2.2rem 2.2rem 6.5rem';

interface ISearchData {
  score?: number;
  chunkIds: string[];
  chunkCount: number;
}

interface IWorkspaceDocumntNodeProps {
  node: TreeNode;
  searchData?: ISearchData;
  selectedItems: string[];
  setSelectedItems: (items: string[]) => void;
}

const WorkspaceTreeNodeComponent: React.FC<IWorkspaceDocumntNodeProps> = (props) => {
  const { node, selectedItems, setSelectedItems, searchData } = props;
  const navigate = useNavigate();
  const { tree } = useWorkspace();
  const { enableDebugMode } = useAppContext();

  const isSelected = selectedItems.includes(node.id);
  const selectNode = () => {
    if (isSelected) {
      setSelectedItems(selectedItems.filter((id) => id !== node.id));
    } else {
      setSelectedItems([...selectedItems, node.id]);
    }
  };

  const categories = node.document?.categories ?? [];
  const language = node.document?.language;
  const jurisdiction = node.document?.jurisdiction;

  return (
    <div
      className="grid gap-2 card-no-padding cursor-pointer select-none"
      style={{
        gridTemplateColumns: COLUMNS_TEMPLATE,
      }}
      data-clickable="true"
      onClick={(evt) => {
        evt.stopPropagation();
        evt.preventDefault();

        if (selectedItems.length) {
          selectNode();
        } else if (node.type === 'folder') {
          navigate(`../${node.id}`);
        } else if (node.type === 'document') {
          navigate(`./${node.id}/?highlightChunks=${(searchData?.chunkIds || []).slice(0, 5).join(';')}`);
        }
      }}
    >
      <div className="flex items-center p-2">
        <StyledCheckbox onChange={selectNode} isChecked={!!isSelected} />
      </div>

      <div className="flex items-center gap-1">
        <div>
          {node.type === 'folder' ? (
            <FolderIcon className="button-icon folder-icon-style" />
          ) : (
            <FileIcon className="button-icon file-icon-style" />
          )}
        </div>
        <div>{node.name}</div>
        <InputDialog
          resetKey={node.id}
          triggerVariant="ghost"
          triggerText={<PencilIcon className="button-icon" />}
          triggerSize={6}
          title={node.type === 'folder' ? 'Rename folder' : 'Rename document'}
          description={`Enter a new name for ${node.getFullpath()}`}
          labelText="New name"
          submitText="Rename"
          initialValue={node.name}
          onSubmit={async (value) => {
            if (node.type === 'folder') {
              try {
                const payload: UpdateFolderDocumentPayload = {
                  folderId: node.id,
                  data: {
                    name: value,
                  },
                };
                await fetchEndpointData(`/api/v1/workspace/folder/update`, {
                  method: 'POST',
                  body: payload,
                });
                toast.success('Folder has been renamed');
              } catch (err) {
                captureException(err);
                toast.error('Could not rename folder: ' + getDisplayError(err));
              }
            } else {
              try {
                const payload: RenameDocumentPayload = {
                  documentId: node.id,
                  newName: value,
                };
                await fetchEndpointData(`/api/v1/workspace/document/rename`, {
                  method: 'POST',
                  body: payload,
                });
                toast.success('Document has been renamed');
              } catch (err) {
                captureException(err);
                toast.error('Could not update document name: ' + getDisplayError(err));
              }
            }
          }}
        />

        {(node.document?.ocrConfidence ?? 1) < 0.8 && (
          <Tooltip text="Document hard to read">
            <div>
              <WarningIcon className="text-danger-color-subtle" size={16} />
            </div>
          </Tooltip>
        )}

        {node.document?.indexingStatus === DocumentIndexingStatus.Invalid && (
          <Tooltip text={`Invalid documents: ${node.document.failureReason}`}>
            <div>
              <XIcon className="text-danger-color-subtle" size={16} />
            </div>
          </Tooltip>
        )}

        {node.document &&
          node.document.indexingStatus !== DocumentIndexingStatus.Invalid &&
          node.document.indexingStatus !== DocumentIndexingStatus.Indexed && <Spinner size={4} />}

        {!!searchData?.chunkCount && searchData.chunkCount >= 1 && (
          <Tooltip text={`${searchData.chunkCount} parts of the document match the search query`}>
            <div>
              <Tag color="orange">{`${searchData.chunkCount} hits`}</Tag>
            </div>
          </Tooltip>
        )}
      </div>

      <div className="flex flex-wrap items-center justify-end gap-2">
        {categories.map((v) => {
          let categoryText = `${v.name}`;
          if (enableDebugMode && v.similarity) {
            categoryText += ` - ${Math.round(v.similarity * 100)}%`;
          }

          return (
            <Tooltip text="Category" key={v.id}>
              <div>
                <Tag color="green">{categoryText}</Tag>
              </div>
            </Tooltip>
          );
        })}
      </div>

      <div className="flex items-center">
        {jurisdiction && (
          <Tooltip text="Jurisdiction">
            <div>
              <Tag color="red">{jurisdiction.toUpperCase()}</Tag>
            </div>
          </Tooltip>
        )}
      </div>

      <div className="flex items-center">
        {language && (
          <Tooltip text="Language">
            <div>
              <Tag color="red">{language.toUpperCase()}</Tag>
            </div>
          </Tooltip>
        )}
      </div>

      <div className="flex items-center pr-2">{formatDate(node.folder?.createdAt)}</div>
    </div>
  );
};

export interface ISortableDocumentHeaderProps {
  title: React.ReactNode;
  direction?: number;
  onSort?: (direction: number) => void;
}

const SortableDocumentHeader: React.FC<ISortableDocumentHeaderProps> = (props) => {
  const { title, direction = 0, onSort } = props;

  return (
    <div
      className={classNames('flex items-center justify-between py-1 px-1 transition-colors duration-150', {
        'hover:bg-gray-200 cursor-pointer': !!onSort,
      })}
      onClick={(evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        if (onSort) {
          if (direction > 0) {
            onSort(0);
          } else if (direction === 0) {
            onSort(-1);
          } else {
            onSort(1);
          }
        }
      }}
    >
      <div>{title}</div>
      {!onSort ? (
        <div></div>
      ) : (
        <div>
          {direction === 0 ? (
            <CaretUpDownIcon className="button-icon" />
          ) : direction > 0 ? (
            <CaretUpIcon className="button-icon" />
          ) : (
            <CaretDownIcon className="button-icon" />
          )}
        </div>
      )}
    </div>
  );
};

const WorkspaceDocumentsPageImpl = () => {
  const navigate = useNavigate();
  const { folderId } = useParams<{ folderId: string }>();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { workspace, tree, treeKey } = useWorkspace();
  const [showCreateFolderDialog, setShowCreateFolderDialog] = useState(false);
  const [sortDirection, setSortDirection] = useState(1);
  const [sortedKey, setSortedKey] = useState('name');

  const parentFolderId = folderId || TREE_ROOT_ID;

  const { isLoading: isLoadingSearchResults, results: searchResults } = useSearch(searchValue, parentFolderId);

  const allKeys = useMemo(() => {
    return (
      tree.entries
        .get(parentFolderId)
        ?.getChildren()
        .map((v) => v.id) ?? []
    );
  }, [treeKey, parentFolderId]);

  const [selectedDocumentIds, selectedFolderIds] = useMemo(() => {
    const selectedDocumentIds: string[] = [];
    const selectedFolderIds: string[] = [];

    for (const itemId of selectedItems) {
      const node = tree.entries.get(itemId);
      if (!node) {
        continue;
      }

      if (node.type === 'folder') {
        selectedFolderIds.push(itemId);
      } else {
        selectedDocumentIds.push(itemId);
      }
    }

    return [selectedDocumentIds, selectedFolderIds];
  }, [selectedItems, treeKey]);

  const nestedSelectionCount = useMemo(() => {
    let count = selectedDocumentIds.length;
    const seenDocIds = new Set<string>(selectedDocumentIds);
    for (const folderId of selectedFolderIds) {
      const docIds = tree.getChildDocumentIds(folderId, true);
      for (const docId of docIds) {
        if (!seenDocIds.has(docId)) {
          count++;
          seenDocIds.add(docId);
        }
      }
    }
    return count;
  }, [selectedDocumentIds, selectedFolderIds]);

  const allowedUploadTypes = useMemo(() => {
    return [...ALLOWED_MIMETYPES];
  }, []);

  const folderNode = tree.getFolderNode(parentFolderId);
  const uploadFolderId = parentFolderId === TREE_ROOT_ID ? null : parentFolderId;

  const closeSearch = () => {
    setSearchValue('');
    setShowSearch(false);
  };

  if (!folderNode) {
    if (tree.isSyncing) {
      return (
        <div>
          <SpinnerBlock message="Loading file tree..." />
        </div>
      );
    } else {
      return <div>Folder not found</div>;
    }
  } else {
    return (
      <div>
        <div className="mb-2">
          <DocumentTreeBreadcrumb
            nodeId={parentFolderId}
            onSelect={(nodeId) => {
              navigate(`../${nodeId}`);
            }}
          />
        </div>

        <div className="flex justify-between items-center mb-2">
          <div className="flex items-center gap-2">
            <div>{selectedItems.length ? `${selectedItems.length} selected` : ''}</div>
          </div>

          <div className="flex gap-2">
            <Button
              size={8}
              onTrigger={() => {
                if (showSearch) {
                  closeSearch();
                } else {
                  setShowSearch(true);
                }
              }}
            >
              {!showSearch ? (
                <div className="flex items-center gap-1">
                  <SearchIcon className="button-icon" />
                  Search
                </div>
              ) : (
                <div className="flex items-center gap-1">
                  <XIcon className="button-icon" />
                  Close Search
                </div>
              )}
            </Button>

            <UploadButton folderId={uploadFolderId} allowedTypes={allowedUploadTypes} />

            <Button size={8} onTrigger={() => setShowCreateFolderDialog((prev) => !prev)}>
              <div className="flex items-center gap-1">
                <PlusIcon className="button-icon" />
                Create Folder
              </div>
            </Button>

            <MoveDialog
              selectedFolderIds={selectedFolderIds}
              selectedDocumentIds={selectedDocumentIds}
              onComplete={() => {
                setSelectedItems([]);
              }}
            />

            <BulkDocumentMetadataDialog
              key={treeKey}
              triggerSize={8}
              triggerText={
                <div className="flex items-center gap-1">
                  <NotePencilIcon className="button-icon" />
                  Metadata
                </div>
              }
              selectedDocumentIds={selectedDocumentIds}
            />

            <ConfirmDialog
              triggerSize={8}
              triggerText={
                <div className="flex items-center gap-1">
                  <TrashIcon className="button-icon" />
                  Delete selected
                </div>
              }
              title="Delete documents"
              submitText="Delete"
              triggerVariant="destructive"
              submitVariant="destructive"
              description={
                <div>
                  Are you sure you want to delete{' '}
                  <span className="font-medium text-danger-color-dark">{nestedSelectionCount} documents</span>?
                </div>
              }
              confirmationText={nestedSelectionCount > 5 ? 'delete' : null}
              isDisabled={!selectedItems.length}
              onSubmit={async () => {
                try {
                  if (selectedDocumentIds.length) {
                    const payload: DeleteDocumentsPayload = {
                      workspaceId: workspace.id,
                      documentIds: selectedDocumentIds,
                    };
                    await fetchEndpointData('/api/v1/workspace/document/delete', {
                      method: 'DELETE',
                      body: payload,
                    });
                  }

                  if (selectedFolderIds.length) {
                    const payload: DeleteFoldersPayload = {
                      workspaceId: workspace.id,
                      folderIds: selectedFolderIds,
                    };
                    await fetchEndpointData(`/api/v1/workspace/folder/delete`, {
                      method: 'DELETE',
                      body: payload,
                    });
                  }

                  setSelectedItems([]);

                  toast.success('Deletions have been queued');
                } catch (err) {
                  captureException(err);
                  toast.error('Could not queue deletions: ' + getDisplayError(err));
                }
              }}
            />
          </div>
        </div>

        <FullPageDropUpload folderId={uploadFolderId} allowedTypes={allowedUploadTypes} />

        {!showSearch && (
          <div
            className="grid gap-2 text-dark-200 bg-gray-100 mb-2 rounded-md overflow-hidden select-none"
            style={{ gridTemplateColumns: COLUMNS_TEMPLATE }}
          >
            <SortableDocumentHeader
              title={
                <div className="ml-1">
                  <StyledCheckbox
                    isChecked={selectedItems.length > 0}
                    onChange={() => {
                      if (selectedItems.length > 0) {
                        setSelectedItems([]);
                      } else {
                        setSelectedItems([...allKeys]);
                      }
                    }}
                  />
                </div>
              }
            />
            <SortableDocumentHeader
              title="Name"
              onSort={(newDirection) => {
                setSortDirection(newDirection);
                setSortedKey('name');
              }}
              direction={sortedKey === 'name' ? sortDirection : 0}
            />
            <SortableDocumentHeader
              title="Categories"
              onSort={(newDirection) => {
                setSortDirection(newDirection);
                setSortedKey('categories');
              }}
              direction={sortedKey === 'categories' ? sortDirection : 0}
            />
            <SortableDocumentHeader
              title={<MapPinIcon className="h-4 w-4" />}
              onSort={(newDirection) => {
                setSortDirection(newDirection);
                setSortedKey('jurisdiction');
              }}
              direction={sortedKey === 'jurisdiction' ? sortDirection : 0}
            />
            <SortableDocumentHeader
              title={<TranslateIcon className="h-4 w-4" />}
              onSort={(newDirection) => {
                setSortDirection(newDirection);
                setSortedKey('language');
              }}
              direction={sortedKey === 'language' ? sortDirection : 0}
            />
            <SortableDocumentHeader
              title="Date"
              onSort={(newDirection) => {
                setSortDirection(newDirection);
                setSortedKey('date');
              }}
              direction={sortedKey === 'date' ? sortDirection : 0}
            />
          </div>
        )}

        {showSearch && (
          <div className="mb-2 flex items-center gap-2">
            <Input
              type="text"
              size={8}
              value={searchValue}
              onChange={setSearchValue}
              placeholder="Search for documents and folders..."
              onKeyDown={(evt) => {
                if (evt.key === 'Escape') {
                  closeSearch();
                }
              }}
            />

            {isLoadingSearchResults && <Spinner size={6} />}

            <Button size={8} onTrigger={closeSearch}>
              <XIcon className="button-icon" />
            </Button>
          </div>
        )}

        <div>
          <CreateFolderDialog
            parentFolderId={parentFolderId}
            showDialog={showCreateFolderDialog}
            setShowDialog={setShowCreateFolderDialog}
          />

          {!folderNode.childCount() && (
            <div className="card-no-padding flex justify-center items-center py-8">
              {tree.isSyncing ? <Spinner size={6} /> : <div>No documents found</div>}
            </div>
          )}

          <div className="grid gap-1">
            {showSearch
              ? searchResults.map((hit) => {
                  return (
                    <WorkspaceTreeNodeComponent
                      key={hit.node.id}
                      node={hit.node}
                      selectedItems={selectedItems}
                      setSelectedItems={setSelectedItems}
                      searchData={{
                        score: hit.score,
                        chunkIds: hit.chunkIds,
                        chunkCount: hit.chunkCount,
                      }}
                    />
                  );
                })
              : folderNode
                  .getChildren()
                  .sort((a, b) => {
                    if (sortedKey === 'name') {
                      return numberAwareTextSort(a.name, b.name) * sortDirection;
                    } else if (sortedKey === 'jurisdiction') {
                      const aJurisdiction = a.document?.jurisdiction ?? '';
                      const bJurisdiction = b.document?.jurisdiction ?? '';
                      return aJurisdiction.localeCompare(bJurisdiction) * sortDirection;
                    } else if (sortedKey === 'language') {
                      const aLang = a.document?.language ?? '';
                      const bLang = b.document?.language ?? '';
                      return aLang.localeCompare(bLang) * sortDirection;
                    } else if (sortedKey === 'date') {
                      const aDate = new Date(a.document?.date ?? a.folder?.createdAt ?? 0).getTime();
                      const bDate = new Date(b.document?.date ?? b.folder?.createdAt ?? 0).getTime();
                      return (bDate - aDate) * sortDirection;
                    } else {
                      const aCategory = a.document?.categories[0]?.name ?? '';
                      const bCategory = b.document?.categories[0]?.name ?? '';
                      return aCategory.localeCompare(bCategory) * sortDirection;
                    }
                  })
                  .map((child) => {
                    return (
                      <WorkspaceTreeNodeComponent
                        key={child.id}
                        node={child}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                      />
                    );
                  })}
          </div>
        </div>
      </div>
    );
  }
};

export const WorkspaceDocumentsPage = () => {
  return (
    <div className="page-content">
      <PageHeader title="Documents" />

      <div className="mb-4 flex justify-between">
        <Breadcrumb
          items={[
            {
              name: 'Documents',
            },
          ]}
        />

        <div className="flex items-center">
          <PendingDocumentUploadsDialog />
          <InvalidDocumentsCountDialog />
          <DocumentProcessingCountDialog />
        </div>
      </div>

      <WorkspaceDocumentsPageImpl />
    </div>
  );
};
