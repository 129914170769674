import { FullPageDropzone, IProcessedFileSystemEntry } from '@/components/file/FullPageDropzone';
import React, { useCallback } from 'react';

export interface ITreeNode {
  name: string;
  children: ITreeNode[];
}

export interface ICategoriesFolderDropProps {
  onDrop: (tree: ITreeNode[]) => void;
}

function postProcessItem(item: IProcessedFileSystemEntry): ITreeNode | null {
  if (item.type === 'folder' && !item.name.startsWith('.')) {
    const node = {
      name: item.name,
      children: [] as ITreeNode[],
    };

    for (const child of item.children) {
      const processedChild = postProcessItem(child);
      if (processedChild) {
        node.children.push(processedChild);
      }
    }

    return node;
  }

  return null;
}

export const CategoriesFolderDrop: React.FC<ICategoriesFolderDropProps> = (props) => {
  const { onDrop } = props;

  const handleDrop = useCallback(
    (items: IProcessedFileSystemEntry[]) => {
      const tree: ITreeNode[] = [];
      for (const item of items) {
        if (item.type === 'folder') {
          const processed = postProcessItem(item);
          if (processed) {
            tree.push(processed);
          }
        }
      }
      onDrop(tree);
    },
    [onDrop],
  );

  return <FullPageDropzone onDrop={handleDrop} text="Drop folders to import your category structure" />;
};
