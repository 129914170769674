import toast from 'react-hot-toast';
import useSWR from 'swr';

import { ResponseType as KnowlexIntegrationResponseType } from '../endpoints/KnowlexIntegrationEndpoint';
import {
  ResponseType as GenerateKnowlexConnectionLinkResponseType,
  BodyType as GenerateKnowlexConnectionLinkPayload,
} from '../endpoints/GenerateKnowlexConnectionLinkEndpoint';
import { fetchEndpointData } from '@/utils/fetch.client';
import { SpinnerBlock } from '@/components/Spinner';
import { PageHeader } from '@/components/PageHeader';
import { Breadcrumb } from '@/components/Breadcrumb';
import { Button } from '@/components/button/Button';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { getDisplayError } from '@/utils/get-display-error';
import { formatDate } from '@/utils/date';

export const KnowlexIntegrationPage = () => {
  const { workspace } = useWorkspace();
  const { data, isLoading } = useSWR<KnowlexIntegrationResponseType>(
    `/api/v1/workspace/integration/knowlex/data/${workspace.id}`,
    fetchEndpointData,
  );

  if (isLoading) {
    return <SpinnerBlock message="Loading knowlex integration..." />;
  }

  const knowlexIntegration = data?.knowlexIntegration;
  return (
    <div className="page-content">
      <PageHeader title="Setup knowlex integration" />

      <div>
        <div className="flex flex-wrap justify-between items-center mb-4">
          <Breadcrumb
            items={[
              {
                name: 'Integrations',
                to: '..',
              },
              {
                name: 'Knowlex Integration',
              },
            ]}
          />
        </div>

        <div className="card">
          <div>
            <Button
              variant={knowlexIntegration ? 'default' : 'primary'}
              onTrigger={async () => {
                try {
                  const payload: GenerateKnowlexConnectionLinkPayload = {
                    workspaceId: workspace.id,
                  };
                  const result = await fetchEndpointData<GenerateKnowlexConnectionLinkResponseType>(
                    '/api/v1/workspace/integration/knowlex/connection-link',
                    {
                      method: 'POST',
                      body: payload,
                    },
                  );
                  window.open(result.connectLink, '_blank');
                } catch (err) {
                  toast.error('Could not generate knowlex connection link: ' + getDisplayError(err));
                }
              }}
            >
              Link Knowlex
            </Button>
          </div>

          {knowlexIntegration && (
            <div className="mt-4">
              <div>{`Linked at ${formatDate(knowlexIntegration.createdAt)}`}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
