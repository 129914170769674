export interface FetchEndpointOpts {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: any;
}

export async function fetchEndpointData<T>(url: string | URL, opts: FetchEndpointOpts = {}): Promise<T> {
  const response = await fetch(url, {
    method: opts.method ?? 'GET',
    body: opts.body ? JSON.stringify(opts.body) : undefined,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });

  const json: any = await response.json();
  if (!response.ok) {
    if (json.error) {
      if (typeof json.error === 'object' && typeof json.error.message === 'string') {
        throw new Error(json.error.message);
      }

      if (typeof json.error === 'string') {
        throw new Error(json.error);
      }
    }

    console.error(json);

    throw new Error(`Request failed: ${response.statusText}`);
  }
  return json as T;
}
