import React, { useEffect, useRef, useState } from 'react';
import { FilePlus as FilePlusIcon } from '@phosphor-icons/react';

export interface IProcessedFileEntry {
  type: 'file';
  name: string;
  file: File;
}

export interface IProcessedDirectoryEntry {
  type: 'folder';
  name: string;
  children: IProcessedFileSystemEntry[];
}

export type IProcessedFileSystemEntry = IProcessedFileEntry | IProcessedDirectoryEntry;

async function parseFsEntry(entry: FileSystemEntry): Promise<IProcessedFileSystemEntry | null> {
  if (entry.isFile) {
    const fileEntry = entry as FileSystemFileEntry;

    return {
      type: 'file',
      name: entry.name,
      file: await new Promise((resolve, reject) => {
        fileEntry.file(resolve, reject);
      }),
    };
  } else if (entry.isDirectory) {
    const dirEntry = entry as FileSystemDirectoryEntry;
    const reader = dirEntry.createReader();

    const children: IProcessedFileSystemEntry[] = [];
    await new Promise<void>((resolve) => {
      const readEntries = () => {
        reader.readEntries(async (entries) => {
          if (!entries.length) {
            resolve();
            return;
          }

          for (const entry of entries) {
            const processedEntry = await parseFsEntry(entry);
            if (processedEntry) {
              children.push(processedEntry);
            }
          }

          readEntries();
        });
      };

      readEntries();
    });

    return {
      type: 'folder',
      name: entry.name,
      children,
    };
  } else {
    return null;
  }
}

function parseTransferItem(item: DataTransferItem) {
  const entry = item.webkitGetAsEntry();
  if (!entry) {
    return null;
  }

  return parseFsEntry(entry);
}

export interface ICategoriesFolderDropProps {
  onDrop: (items: IProcessedFileSystemEntry[]) => void;
  text: string;
}

export const FullPageDropzone: React.FC<ICategoriesFolderDropProps> = (props) => {
  const { text, onDrop } = props;

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDroppingFiles, setIsDroppingFiles] = useState<number>(0);
  const dragCountRef = useRef(0);

  const lastUpdatedRef = useRef(Date.now());
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const updateDragCounter = (diff: number) => {
      dragCountRef.current = dragCountRef.current + diff;
    };

    const handleWindowDragEnter = (evt: DragEvent) => {
      evt.preventDefault();
      evt.stopPropagation();

      updateDragCounter(1);

      if (evt.dataTransfer && evt.dataTransfer.items && evt.dataTransfer.items.length > 0) {
        setIsDroppingFiles(evt.dataTransfer.items.length);
      }
    };

    const handleWindowDragLeave = (evt: DragEvent) => {
      evt.preventDefault();
      evt.stopPropagation();

      updateDragCounter(-1);

      if (dragCountRef.current === 0) {
        setIsDroppingFiles(0);
      }
    };

    const handleWindowDragOver = (evt: DragEvent) => {
      evt.preventDefault();
      evt.stopPropagation();

      const diff = Date.now() - lastUpdatedRef.current;
      if (diff < 10) {
        timeoutRef.current = setTimeout(() => {
          setPosition({
            x: evt.clientX,
            y: evt.clientY,
          });
        }, diff);
      } else {
        lastUpdatedRef.current = Date.now();
        setPosition({
          x: evt.clientX,
          y: evt.clientY,
        });
      }
    };

    const handleWindowDrop = async (evt: DragEvent) => {
      evt.preventDefault();
      evt.stopPropagation();

      setIsDroppingFiles(0);

      if (evt.dataTransfer) {
        const droppedItems = await Promise.all([...evt.dataTransfer.items].map((v) => parseTransferItem(v)));
        onDrop(droppedItems.filter((v) => !!v));
      }
    };

    // Add window event listeners
    window.addEventListener('dragenter', handleWindowDragEnter);
    window.addEventListener('dragleave', handleWindowDragLeave);
    window.addEventListener('dragover', handleWindowDragOver);
    window.addEventListener('drop', handleWindowDrop);

    // Cleanup
    return () => {
      window.removeEventListener('dragenter', handleWindowDragEnter);
      window.removeEventListener('dragleave', handleWindowDragLeave);
      window.removeEventListener('dragover', handleWindowDragOver);
      window.removeEventListener('drop', handleWindowDrop);
    };
  }, [onDrop]);

  if (isDroppingFiles < 1) {
    return null;
  }

  return (
    <div
      className="fixed w-screen h-screen top-0 left-0 z-dialog p-2"
      style={{
        backgroundColor: 'rgba(100, 132, 234, 0.2)',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: position.y,
          left: position.x,
        }}
      >
        <div className="relative">
          {new Array(isDroppingFiles).fill(0).map((_, index) => {
            const angle = (index / isDroppingFiles) * 2 * Math.PI;
            const radius = 120;
            const x = isDroppingFiles === 1 ? 0 : Math.cos(angle) * radius;
            const y = isDroppingFiles === 1 ? 0 : Math.sin(angle) * radius;
            const rotation = Math.sin(Date.now() * 0.001 + index) * 45;

            return (
              <FilePlusIcon
                key={`file-icon-${index}`}
                className="absolute w-12 h-12 fill-primary-500 transition-all duration-200 ease-out"
                style={{
                  transform: `translate(${x - 16}px, ${y - 16}px) rotate(${rotation}deg)`,
                }}
              />
            );
          })}
        </div>
      </div>

      <div className="border-2 border-dashed border-blue-400 rounded-lg w-full h-full flex justify-center items-center">
        <div className="text-xl">{text}</div>
      </div>
    </div>
  );
};
