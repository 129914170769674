export function normalizeParagraph(content: string): string {
  return content
    .split('\n')
    .map((v) => cleanUtf8(v.trim()))
    .join('\n')
    .trim();
}

export function normalizeForHash(content: string): string {
  return normalizeParagraph(content).toLowerCase().replace(/\s+/, ' ');
}

export function cleanText(content: string): string {
  return cleanUtf8(
    content
      // Remove spaces before ":"
      .replace(/\s+:/g, ':')
      // Remove spaces between brackets
      .replace(/\s+(]|\)|})/g, '$1')
      .replace(/(\[|\(|{)\s+/g, '$1')
      // Normalize list symbol
      .replace(/[•●○■▪▫–—][^\S\r\n]+/g, '- ')
      .replace(/[■▪▫–—]/g, '-')
      // Normalize degree symbol
      .replace(/(\d+)[º]/g, '$1°'),
  );
}

/**
 * Remove non-utf8 characters from a string
 */
export function cleanUtf8(str: string): string {
  return (
    str
      .normalize('NFKD')
      // Remove control characters except tabs and newlines
      .replace(/[\x00-\x08\x0B\x0C\x0E-\x1F\x7F-\x9F]/g, '')
      // Remove surrogate pairs
      .replace(/[\uD800-\uDFFF]/g, '')
      // Remove non-characters
      .replace(/[\uFDD0-\uFDEF\uFFFE\uFFFF]/g, '')
      // Remove unassigned codepoints
      .replace(/\r/g, '')
      // Normalize again to handle any remaining decomposed characters
      .normalize('NFC')
  );
}

export function removeEmptyLines(content: string) {
  const lines = content
    .split('\n')
    .map((l) => l.trim())
    .filter((l) => l.length > 0);
  return lines.join('\n');
}

export function removeNewlines(summary: string) {
  return summary.trim().replace(/\s+/g, ' ');
}

export function formatBytes(bytes: number): string {
  if (bytes === 0) {
    return '0B';
  }

  let sizeType = 'B';
  let sizes = ['KB', 'MB', 'GB', 'TB'];
  while (bytes >= 1024 || !sizes.length) {
    bytes /= 1024;
    // @ts-ignore
    sizeType = sizes.shift();
  }

  return `${bytes.toFixed(2)}${sizeType}`;
}

export function trimText(txt: string, maxLen: number) {
  const trimmed = txt.trim();
  if (trimmed.length + 5 > maxLen) {
    return trimmed.slice(0, maxLen) + '...';
  } else {
    return trimmed;
  }
}

export function extractNumberParts(val: string): (string | number)[] {
  const parts = val.split(/(\d+)/);
  return parts
    .map((p) => {
      const num = parseInt(p, 10);
      return isNaN(num) ? p : num;
    })
    .filter(Boolean);
}

export function numberAwareTextSort(a: string, b: string): number {
  const aParts = a
    .replace(/[\s_-]+/, ' ')
    .trim()
    .split(' ');
  const bParts = b
    .replace(/[\s_-]+/, ' ')
    .trim()
    .split(' ');

  const minPartCount = Math.min(aParts.length, bParts.length);
  for (let i = 0; i < minPartCount; i++) {
    const aPart = aParts[i]!;
    const bPart = bParts[i]!;

    if (aPart === bPart) {
      continue;
    }

    const aNumParts = extractNumberParts(aPart);
    const bNumParts = extractNumberParts(bPart);
    const minNumParts = Math.min(aNumParts.length, bNumParts.length);
    for (let i = 0; i < minNumParts; i++) {
      const aNumPart = aNumParts[i]!;
      const bNumPart = bNumParts[i]!;

      if (typeof aNumPart === 'number' && typeof bNumPart === 'number') {
        if (aNumPart === bNumPart) {
          continue;
        } else {
          return aNumPart > bNumPart ? 1 : -1;
        }
      } else {
        const compareRes = ('' + aNumPart).localeCompare('' + bNumPart);
        if (compareRes === 0) {
          continue;
        }
        return compareRes;
      }
    }
  }

  if (a.length === b.length) {
    return 0;
  } else {
    return a.length > b.length ? 1 : -1;
  }
}
