import { useEffect, useRef } from 'react';

export function useDebounce(callback: () => void, key: string, ms: number) {
  const cbRef = useRef(callback);

  useEffect(() => {
    cbRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      cbRef.current();
    }, ms);

    return () => {
      clearTimeout(timeoutRef);
    };
  }, [key, ms]);
}
