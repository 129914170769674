import * as React from 'react';
import useSWR from 'swr';

import createContext from './create-context';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { fetchEndpointData } from '@/utils/fetch.client';
import { ResponseType as VersionsResult } from '../app/versions/VersionsEndpoint';
import { SpinnerBlock } from '@/components/Spinner';

interface ProviderValue {
  enableDebugMode: boolean;
  setEnableDebugMode: (val: boolean) => void;
  versions: VersionsResult;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface AppProviderProps {
  children?: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = (props) => {
  const { children } = props;
  const [enableDebugMode, setEnableDebugMode] = useLocalStorage('debug-mode', false);
  const { data, error, isLoading } = useSWR<VersionsResult>('/api/v1/versions', fetchEndpointData);

  if (isLoading) {
    return <SpinnerBlock message="Loading..." />;
  }

  if (!data) {
    if (error) {
      throw error;
    } else {
      return <div>Failed to load application. Refresh to try again.</div>;
    }
  }

  return <ReactProvider value={{ enableDebugMode, setEnableDebugMode, versions: data }}>{children}</ReactProvider>;
};

export const useAppContext = useContext;
export const AppContextConsumer = ReactConsumer;
