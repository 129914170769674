// !BEWARE!: USED ON CLIENT AND SERVER

export const ALLOWED_MIMETYPES = new Set([
  // Text
  'text/plain',
  'text/html',

  // RTF
  'application/rtf',
  'text/rtf',

  // PDF
  'application/pdf',

  // Office files
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/excel',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

  // Email files
  '.msg', // The browser does not understand "application/vnd.ms-outlook"
  'application/vnd.ms-outlook',
  'message/rfc822',

  // Image Files
  'image/jpeg',
  'image/png',

  // ZIP File
  'application/zip',
  'application/x-zip-compressed',
]);

export const REF_RE = /<ref#([^\>]*)>|<ref>([^\<\>]*)<\/ref>|\[ref#([^\]]*)\]/g;
export const UNKNOWN_TAGS_RE = /<.*>/g;

function getRefIds(refs: string): string[] {
  return refs.split(/,|;/g).map((ref) => ref.trim());
}

export const getReferenceIdsFromMatch = (match: RegExpMatchArray): string[] => {
  if (match[1] || match[2]) {
    return getRefIds(match[1] || match[2] || '');
  } else if (match[3]) {
    return getRefIds(match[3]);
  } else {
    return [];
  }
};
