import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { captureException } from '@sentry/react';

import { useTeam } from '@/app/team/context/TeamContext';
import { PageHeader } from '../../../components/PageHeader';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { getDisplayError } from '../../../utils/get-display-error';
import { InputField } from '../../../components/input/InputField';
import { Button } from '../../../components/button/Button';
import type {
  BodyType as CreateWorkspacePayload,
  ResponseType as CreateWorkspaceResponseType,
} from '../endpoints/CreateWorkspaceEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';

export const CreateWorkspacePage = () => {
  const { team } = useTeam();
  const navigate = useNavigate();

  const title = 'New workspace';
  return (
    <div className="page-content">
      <PageHeader title={title} />

      <div className="h-full">
        <div className="flex justify-between items-center mb-4">
          <Breadcrumb
            items={[
              {
                name: 'Workspaces',
                to: '..',
              },
              {
                name: title,
              },
            ]}
          />
        </div>

        <div className="card">
          <Formik
            initialValues={{
              name: '',
              documentFolderId: '',
            }}
            onSubmit={async (values) => {
              try {
                const payload: CreateWorkspacePayload = {
                  teamId: team.id,
                  data: {
                    name: values.name,
                  },
                };
                const result = await fetchEndpointData<CreateWorkspaceResponseType>('/api/v1/workspace/create', {
                  method: 'POST',
                  body: payload,
                });
                navigate(`../${result.workspace.id}`);
                toast.success('Workspace created successfully');
              } catch (err) {
                captureException(err);
                console.error(err);
                toast.error('Failed to create workspace: ' + getDisplayError(err));
              }
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <InputField type="text" name="name" labelText="Name" />

                <Button variant="primary" type="submit" isDisabled={isSubmitting} isLoading={isSubmitting}>
                  Create workspace
                </Button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
