import dayjs from 'dayjs';

import { NaNToZero } from './number';

export function formatDate(input: dayjs.ConfigType): string {
  return dayjs(input).format('DD MMM YYYY');
}

export function formatDateTime(input: dayjs.ConfigType) {
  return dayjs(input).format('DD MMM YYYY HH:mm');
}

export function formatInputDate(input: dayjs.ConfigType): string {
  return dayjs(input).format('YYYY-MM-DD');
}

export function parseInputTime(input: string): number {
  const parsed = input.split(':');
  if (parsed.length < 2 || parsed[0]!.startsWith('-') || parsed[1]!.startsWith('-')) {
    return 0;
  }
  const hours = NaNToZero(+parsed[0]!);
  const minutes = NaNToZero(+parsed[1]!);
  const result = hours * 60 + minutes;
  return result;
}

export function formatInputTime(minutes: number): string {
  if (minutes <= 0) {
    return '00:00';
  }

  const hours = `${Math.floor(minutes / 60)}`.padStart(2, '0');
  const mins = `${Math.round(minutes % 60)}`.padStart(2, '0');
  return `${hours}:${mins}`;
}

export function isDateTimeBefore(startDate: string, startTime: string, endDate: string, endTime: string) {
  try {
    const parsedStart = dayjs(startDate).add(parseInputTime(startTime), 'minutes');
    const parsedEnd = dayjs(endDate).add(parseInputTime(endTime), 'minutes');
    return parsedStart.isBefore(parsedEnd);
  } catch (err) {
    return true;
  }
}

export function isTimeBefore(startTime: string, endTime: string) {
  try {
    const parsedStart = parseInputTime(startTime);
    const parsedEnd = parseInputTime(endTime);
    return parsedStart < parsedEnd;
  } catch (err) {
    return true;
  }
}
