import { lexer as mdLexer, Token as MdToken, Tokens as MdTokens } from 'marked';
import { createElement, useId, useMemo } from 'react';

import classNames from '@/utils/classnames';
import { parseMarkdown } from './parser';

export interface IMarkdownNodeProps {
  token: MdToken;
  isOnlyElement?: boolean;
  position?: 'first' | 'middle' | 'last';
  renderText: (text: string) => React.ReactNode;
}

export const MarkdownNode: React.FC<IMarkdownNodeProps> = (props) => {
  const { token, isOnlyElement = false, position = 'middle', renderText } = props;
  const id = useId();

  const margins = isOnlyElement ? '' : position === 'first' ? 'mb-2' : position === 'last' ? 'mt-2' : 'my-2';
  switch (token.type) {
    case 'text':
      const textToken = token as MdTokens.Text;

      return (
        <span>
          {textToken.tokens
            ? textToken.tokens.map((t, i) => {
                return <MarkdownNode key={`${id}-${i}`} token={t} renderText={renderText} />;
              })
            : renderText(textToken.text)}
        </span>
      );
    case 'strong':
      return (
        <span className="font-medium">
          {token.tokens?.map((t, i) => {
            return <MarkdownNode key={`${id}-${i}`} token={t} renderText={renderText} />;
          })}
        </span>
      );
    case 'em':
      return (
        <span className="italic">
          {token.tokens?.map((t, i) => {
            return <MarkdownNode key={`${id}-${i}`} token={t} renderText={renderText} />;
          })}
        </span>
      );
    case 'paragraph':
      return (
        <p className={margins}>
          {token.tokens?.map((t, i) => {
            return <MarkdownNode key={`${id}-${i}`} token={t} renderText={renderText} />;
          })}
        </p>
      );
    case 'list':
      const listToken = token as MdTokens.List;

      return createElement(
        listToken.ordered ? 'ol' : 'ul',
        {
          className: classNames(listToken.ordered ? 'list-decimal' : 'list-disc', margins),
          start: listToken.start,
        },
        listToken.items?.map((item, i) => {
          return <MarkdownNode key={`${id}-${i}`} token={item} renderText={renderText} />;
        }),
      );
    case 'list_item':
      const number = token.raw.trim().match(/^\d+/)?.[0];

      return (
        <li className="flex">
          <div className="pr-1">{number ? `${number}.` : '-'}</div>
          <div>
            {token.tokens?.map((t, i) => {
              return <MarkdownNode key={`${id}-${i}`} token={t} renderText={renderText} />;
            })}
          </div>
        </li>
      );
    case 'heading':
      const depth = (token as MdTokens.Heading).depth;

      return createElement(
        `h${depth}`,
        {
          className: classNames('mb-1 font-medium', {
            'text-lg font-bold': depth === 1,
            'text-md font-bold': depth === 2,
          }),
        },
        token.tokens?.map((t, i) => {
          return <MarkdownNode key={`${id}-${i}`} token={t} renderText={renderText} />;
        }),
      );
    case 'table':
      return (
        <table className="w-full">
          {token.header.length > 0 && (
            <thead>
              <tr>
                {token.header.map((v: MdTokens.TableCell, i: number) => {
                  return (
                    <th key={i} className="border border-gray-300 px-2 text-left">
                      {v.tokens?.map((t, k) => {
                        return <MarkdownNode key={`${id}-${k}`} token={t} renderText={renderText} />;
                      })}
                    </th>
                  );
                })}
              </tr>
            </thead>
          )}

          <tbody>
            {token.rows.map((cells: MdTokens.TableCell[], i: number) => {
              return (
                <tr key={i}>
                  {cells.map((v, j) => {
                    return (
                      <td key={j} className="border border-gray-300 px-2">
                        {v.tokens?.map((t, k) => {
                          return <MarkdownNode key={`${id}-${k}`} token={t} renderText={renderText} />;
                        })}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    case 'space':
      return null;
    case 'html':
      return renderText(token.raw);
    default:
      console.log(token);
      console.error('Unhandled token', token);
      return <div>{renderText(token.raw)}</div>;
  }
};

export interface IMarkdownTextProps {
  content: string;
  renderText: (text: string) => React.ReactNode;
  ignoredTokens?: string[];
}

export const MarkdownText: React.FC<IMarkdownTextProps> = (props) => {
  const { content, renderText, ignoredTokens } = props;
  const id = useId();

  const parsedMd = useMemo(() => {
    return parseMarkdown(content, {
      ignoredTokens: ignoredTokens || ['space'],
    });
  }, [content, ignoredTokens]);

  return (
    <div>
      {parsedMd.tokens.map((token, i) => {
        return (
          <MarkdownNode
            key={`${id}-${i}`}
            token={token}
            isOnlyElement={i === 0 && parsedMd.tokens.length === 1}
            position={i === 0 ? 'first' : i === parsedMd.tokens.length - 1 ? 'last' : 'middle'}
            renderText={renderText}
          />
        );
      })}
    </div>
  );
};
