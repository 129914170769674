import { captureException } from '@sentry/react';
import { ThumbsDown as ThumbsDownIcon } from '@phosphor-icons/react';
import { useState } from 'react';
import toast from 'react-hot-toast';

import { BaseButton } from '../../../components/button/BaseButton';
import { CheckButton } from '../../../components/checkbox/CheckButton';
import { Button } from '../../../components/button/Button';
import { DialogContent, DialogRoot, DialogTrigger } from '../../../components/dialog/Dialog';
import { BodyType as FlagChatMessagePayload } from '../../workspaceChat/endpoints/FlagChatMessageEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';

const REASONS = [
  {
    title: 'Incomplete',
    value: 'incomplete',
  },
  {
    title: 'Inaccurate',
    value: 'inaccurate',
  },
  {
    title: 'Too short',
    value: 'short',
  },
  {
    title: 'Too long',
    value: 'long',
  },
  {
    title: 'Made up answer',
    value: 'hallucination',
  },
];

export interface IFlaggingDialogProps {
  messageId: string;
}

export const FlaggingDialog: React.FC<IFlaggingDialogProps> = (props) => {
  const { messageId } = props;
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [reasons, setReasons] = useState<Set<string>>(new Set());

  return (
    <DialogRoot open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <BaseButton
          isLoading={isFetching}
          variant="ghost"
          onClick={() => {
            setOpen(true);
          }}
        >
          <ThumbsDownIcon className="button-icon" />
        </BaseButton>
      </DialogTrigger>

      <DialogContent className="dialog-content">
        <h1 className="heading-one mb-2">Help us improve</h1>
        <div className="mb-4">Select all the reasons that apply.</div>

        <div className="grid grid-cols-2 gap-2">
          {REASONS.map((v, idx) => {
            return (
              <CheckButton
                key={idx}
                labelText={v.title}
                isChecked={reasons.has(v.value)}
                onCheck={() => {
                  setReasons((prev) => {
                    if (prev.has(v.value)) {
                      prev.delete(v.value);
                      return new Set([...prev]);
                    } else {
                      return new Set([...prev, v.value]);
                    }
                  });
                }}
              />
            );
          })}
        </div>

        <div className="mt-4 flex justify-end">
          <Button
            variant="primary"
            isDisabled={!reasons.size}
            isLoading={isFetching}
            onTrigger={async () => {
              setIsFetching(true);

              try {
                const payload: FlagChatMessagePayload = {
                  messageId,
                  reason: [...reasons].join(';'),
                };

                await fetchEndpointData('/api/v1/workspace/chat/flag-message', {
                  method: 'POST',
                  body: payload,
                });

                setOpen(false);

                toast.success('Response review sent');
              } catch (err) {
                captureException(err);
                console.error(err);

                toast.error('Could not send response review');
              }

              setIsFetching(false);
            }}
          >
            Send Feedback
          </Button>
        </div>
      </DialogContent>
    </DialogRoot>
  );
};
